import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Carousel,
  DatePicker,
  Input,
  NavRS,
} from "../../../../../components";
import { InputType } from "../../../../../components/Input";
import {
  getLocalizedHomepage,
  languageSelector,
} from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";
import withDirection, {
  withDirectionPropTypes,
  DIRECTIONS,
  //@ts-ignore
} from "react-with-direction";
import "./style.scss";
import {
  LocationObject,
  SliderObject,
  SliderTimeObject,
} from "../../../../../types";
import {
  getHomepageSliders,
  getHomepageSliderTime,
  getLocations,
} from "../../../../../redux/actions";
import { Autocomplete } from "devextreme-react";
import moment from "moment";
import { Modal } from "rsuite";
import ModalBody from "rsuite/lib/Modal/ModalBody";
import AutoCompleteRs from "../../../../../components/AutoCompleteRs";

const Opener = ({ direction }: withDirectionPropTypes) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector(languageSelector);
  const strings = useSelector(getLocalizedHomepage);
  const [sliders, setSliders] = useState<SliderObject[]>();
  const [sliderTime, setSliderTime] = useState<SliderTimeObject>();
  const [active, setActive] = useState<number>(1);
  const [show, setShow] = useState<boolean>(false);
  const [tons, setTons] = useState<string>(strings.getString(""));
  const [loadings, setLoadings] = useState<LocationObject[]>();
  const [unloadings, setUnloadings] = useState<LocationObject[]>();
  const content = [
    { name: strings.getString("trucks"), icon: "cargo-truck" },
    { name: strings.getString("loads"), icon: "box" },
  ];

  const [loading, setLoading] = useState<string>("");
  const [unLoading, setUnloading] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");

  useEffect(() => {
    dispatch(
      getHomepageSliders({
        payload: {
          onSuccess: (message, payload) => setSliders(payload),
          onError: (message) => { },
        },
        url: "HomePage/GetSliders",
      })
    );
    dispatch(
      getHomepageSliderTime({
        payload: {
          onSuccess: (message, payload) => setSliderTime(payload),
          onError: (message) => { },
        },
        url: "HomePage/GetSliders",
      })
    );
  }, [dispatch, language]);

  return (
    <div className="openerPage" id="homepage">
      <>
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          className={"actionCard-mobile-modal"}
        >
          <ModalBody>
            <NavRS
              centerClass={true}
              content={content}
              appearance="subtle"
              activeKey={active}
              onSelect={(eventKey: number) => setActive(eventKey)}
            />
            <div style={{ padding: 20 }}>
              <div style={{ width: "100%", marginBlockStart: 0 }}>
                <Autocomplete
                  dataSource={loadings}
                  valueExpr="name"
                  onValueChanged={(w) => {
                    if (w.value ? w.value.length >= 3 : false) {
                      dispatch(
                        getLocations({
                          payload: {
                            onSuccess: (message, payload) =>
                              setLoadings(payload),
                            onError: (message) => { },
                          },
                          url: `HomePage/GetLocations?SearchParam=${w.value}`,
                        })
                      );
                    }
                  }}
                  onSelectionChanged={(e) => {
                    e.selectedItem && setLoading(e.selectedItem.sfid);
                  }}
                  placeholder={strings.getString("loading")}
                />
              </div>
              <div style={{ width: "100%" }}>
                <Autocomplete
                  style={{ marginBlock: 5 }}
                  dataSource={unloadings}
                  valueExpr="name"
                  onValueChanged={(w) => {
                    if (w.value ? w.value.length >= 3 : false) {
                      dispatch(
                        getLocations({
                          payload: {
                            onSuccess: (message, payload) =>
                              setUnloadings(payload),
                            onError: (message) => { },
                          },
                          url: `HomePage/GetLocations?SearchParam=${w.value}`,
                        })
                      );
                    }
                  }}
                  onSelectionChanged={(e) => {
                    e.selectedItem && setUnloading(e.selectedItem.sfid);
                  }}
                  placeholder={strings.getString("unloading")}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "47%" }}>
                  <DatePicker
                    placement="topStart"
                    hideLabel
                    placeholder={strings.getString("starting_from")}
                    onChange={(e) => {
                      console.log("Tarih", e);
                      const startDate = e ? moment(e).format() : "";
                      setStartDate(startDate);
                    }}
                  />
                </div>
                <div style={{ width: "47%", marginBlockStart: 4 }}>
                  <Input
                    inputType={InputType.number}
                    placeholder={strings.getString("tons")}
                    onChange={(e: any) => {
                      console.log();
                      setTons(e);
                    }}
                    value={tons}
                  />
                </div>
              </div>
              <Button
                title={
                  active === 1
                    ? strings.getString("search_truck")
                    : strings.getString("search_load")
                }
                icon={direction === DIRECTIONS.LTR ? "forward" : "backward"}
                iconPosition="end"
                iconSize={16}
                style={{ fontSize: 16, fontWeight: 400 }}
                onClick={() => {
                  active === 1
                    ? history.push(
                      `/search-trucks?departure=${loading}&destination=${unLoading}&start=${startDate}&tonMax=${tons}`
                    )
                    : history.push(
                      `/search-loads?departure=${loading}&destination=${unLoading}&start=${startDate}&tonMax=${tons}`
                    );
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </>
      <Carousel data={sliders || []} time={sliderTime} />
      <div className="openerPageContent homepageWidth">
        <div
          className="actionCard-mobile"
          style={{ backgroundColor: color.accent }}
        >
          <Button
            title={strings.getString("search")}
            icon={direction === DIRECTIONS.LTR ? "forward" : "backward"}
            iconPosition="end"
            iconSize={16}
            style={{ fontSize: 16, fontWeight: 400, width: 200 }}
            onClick={() => {
              setShow(true);
            }}
          />
        </div>
        <div className="actionCard" style={{ backgroundColor: color.accent }}>
          <NavRS
            content={content}
            appearance="subtle"
            activeKey={active}
            onSelect={(eventKey: number) => setActive(eventKey)}
          />
          <div style={{ width: "100%", marginBlockStart: 40 }}>
            <AutoCompleteRs
              action={getLocations}
              url="HomePage/GetLocations"
              onselectitem={(value) => {
                setLoading(value);
                console.log(value, "loading");
              }}
              placeholder={strings.getString("loading")}
              style={{ marginBlock: 5 }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <AutoCompleteRs
              action={getLocations}
              url="HomePage/GetLocations"
              onselectitem={(value) => {
                console.log(value, "unloading");
                setUnloading(value);
              }}
              placeholder={strings.getString("unloading")}
              style={{ marginBlock: 5 }}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "47%" }}>
              <DatePicker
                placement="topStart"
                placeholder={strings.getString("starting_from")}
                onChange={(e) => {
                  console.log("Tarih", e);
                  const startDate = e ? moment(e).format() : "";
                  setStartDate(startDate);
                }}
              />
            </div>
            <div style={{ width: "47%", marginBlockStart: 4 }}>
              <Input
                inputType={InputType.default}
                type="number"
                placeholder={strings.getString("tons")}
                onChange={(e: any) => {
                  console.log();
                  setTons(e);
                }}
                value={tons}
              />
            </div>
          </div>
          <Button
            className="homepagebutton"
            title={
              active === 1
                ? strings.getString("search_truck")
                : strings.getString("search_load")
            }
            icon={direction === DIRECTIONS.LTR ? "forward" : "backward"}
            iconPosition="end"
            iconSize={16}
            style={{ fontSize: 16, fontWeight: 400 }}
            onClick={() => {
              active === 1
                ? history.push(
                  `/search-trucks?departure=${loading}&destination=${unLoading}&start=${startDate}&tonMax=${tons}`
                )
                : history.push(
                  `/search-loads?departure=${loading}&destination=${unLoading}&start=${startDate}&tonMax=${tons}`
                );
            }}
          />
          <div className="mobile-homepage-buttons">
            <Button
              className="homepagebutton"
              title={strings.getString("have_truck")}
              style={{ fontSize: 16, fontWeight: 400, width: "48%" }}
              onClick={() => {
                history.push("/truck-registration");
              }}
            />
            <Button
              className="homepagebutton"
              title={strings.getString("have_load")}
              style={{ fontSize: 16, fontWeight: 400, width: "48%" }}
              onClick={() => {
                history.push("/load-registration");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withDirection(Opener);
